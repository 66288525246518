.onboarding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    min-height: 60vh;
}

.connect-button {
    background-color: #F1641D;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.connect-button:hover {
    background-color: #dc5816;
}

.onboarding-container h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.note {
    font-size: 0.8rem;
    color: #666;
    font-style: italic;
    margin-top: 2rem;
}