.links-manager {
    margin-top: 30px;
    padding-top: 20px;
}

.links-manager h4 {
    margin-bottom: 12px;
    color: #333;
}

.add-link-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.add-link-form input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: inherit;
}

.add-link-form button {
    padding: 8px;
    font-family: inherit;
    background: #f1641d;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.add-link-form select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: inherit;
    color: #666;
}

.links-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.link-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #f8f9fa;
    border-radius: 8px;
}

.link-info {
    flex: 1;
}

.link-title {
    font-size: 0.8rem;
    font-weight: bold;
}

.link-url {
    font-size: 0.8rem;
    color: #666;
}

.link-actions {
    display: flex;
    gap: 5px;
}

.link-actions button {
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: #fff;
}

.remove-link {
    color: #ff4444;
} 