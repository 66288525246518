* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem; /* Hardcoded spacing */
  }
  
  /* Navigation */
  .navbar {
    padding: 1rem 0;
    background: #ffffff; /* Hardcoded background color */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: #F1641D; /* Hardcoded primary color */
  }
  
  /* Buttons */
  .cta-button {
    display: inline-block;
    font-family: inherit;
    padding: 0.8rem 1.5rem;
    background-color: #F1641D; /* Hardcoded primary color */
    color: white;
    text-decoration: none;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    transition: transform 0.2s ease;
    cursor: pointer;
  }
  
  .cta-button:hover {
    transform: translateY(-2px);
  }
  
  /* Hero Section */
  .hero {
    padding: 4rem 0;
    text-align: center;
    background: linear-gradient(to bottom, #fff, #f5f5f5); /* Hardcoded gray light */
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    line-height: 1.2;
  }
  
  .hero .subtitle {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 2rem;
  }
  
  /* Features Section */
  .features {
    padding: 4rem 0;
    background: #ffffff; /* Hardcoded background color */
  }
  
  .features h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .feature-card {
    padding: 2rem;
    background: #f5f5f5; /* Hardcoded gray light */
    border-radius: 12px;
    text-align: center;
  }
  
  .feature-card h3 {
    margin-bottom: 1rem;
    color: #F1641D; /* Hardcoded primary color */
  }
  
  /* Explanation Section */
  .explanation {
    padding: 6rem 0;  /* Increased padding for more breathing room */
  }
  
  .explanation-content {
    display: grid;
    grid-template-columns: 1.2fr 1fr;  /* Give text area more space */
    gap: 6rem;  /* Increased gap between text and image */
    align-items: center;
    max-width: 1100px;  /* Limit maximum width for better readability */
    margin: 0 auto;
  }
  
  .explanation-text h2 {
    color: #333;
    margin-bottom: 2rem;  /* More space after heading */
    font-size: 2.2rem;
  }
  
  .explanation-text p {
    margin-bottom: 1.5rem;  /* More space between paragraphs */
    line-height: 1.7;
    color: #666;
    font-size: 1.1rem;
  }
  
  .explanation-image {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;  /* Center the phone horizontally */
  }
  
  /* iPhone mockup styles - matching Admin.css */
  .home-preview .iphone-mockup {
    background: #1a1a1a;
    border-radius: 50px;
    padding: 8px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    width: 275px;
    height: 602px;
    position: relative;
    margin-bottom: 1rem;
  }
  
  .home-preview .iphone-mockup::before {
    content: '';
    position: absolute;
    width: 150px;
    height: 25px;
    background: #1a1a1a;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    z-index: 2;
  }
  
  .home-preview .iphone-screen {
    background: white;
    border-radius: 40px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    
    /* Hide scrollbars */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .home-preview .iphone-screen img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  @media (max-width: 1024px) {
    .home-preview .iphone-mockup {
        width: 260px;
        height: 570px;
    }
  }
  
  @media (max-width: 768px) {
    .explanation {
      padding: 4rem 0;
    }
  
    .explanation-content {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
    
    .explanation-text h2 {
      font-size: 2rem;
      text-align: center;
    }
    
    .explanation-image {
      margin: 0 auto;
    }
    
    .home-preview .iphone-mockup {
        width: 260px;
        height: 570px;
    }
  }
  
  /* CTA Section */
  .cta {
    padding: 4rem 0;
    text-align: center;
    background: #F1641D; /* Hardcoded primary color */
    color: white;
  }
  
  .cta h2 {
    margin-bottom: 1rem;
  }
  
  .cta p {
    margin-bottom: 2rem;
  }
  
  .cta .cta-button {
    background: white;
    color: #F1641D; /* Hardcoded primary color */
    cursor: pointer;
  }
  
  /* Footer */
  footer {
    padding: 2rem 0;
    background: white; /* Hardcoded gray light */
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-left p {
    margin: 0.5rem 0; /* Added margin for spacing between footer items */
  }

  .clickable-item {
    cursor: pointer;
  }