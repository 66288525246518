.admin-container {
    display: grid;
    grid-template-columns: auto minmax(300px, 1fr) minmax(375px, 1fr);
    height: 100vh;
    overflow: hidden;
}

/* Navigation Column */
.admin-nav {
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
    border-right: 1px solid #e9ecef;
    width: 200px;
    padding: 1rem 0;
    min-width: 200px;
    max-width: 200px;
}

.admin-nav-header {
    padding: 0 1rem;
    margin-bottom: 2rem;
}

.admin-logo {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: left;
}

.admin-nav-buttons {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 0.5rem;
    align-items: flex-start;
}

.nav-button {
    font-family: inherit;
    background: none;
    border: none;
    padding: 0.75rem;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 1rem;
    width: 100%;
}

.nav-button:hover {
    background-color: #e9ecef;
}

.nav-button.active {
    background-color: #e9ecef;
    font-weight: 600;
}

.admin-nav-footer {
    padding: 0.5rem;
    border-top: 1px solid #e9ecef;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: inherit;
}

.admin-nav-footer :global(.profile-name) {
    white-space: nowrap;
    font-family: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75rem;
    text-align: center;
    width: 100%;
}

/* Manager Column */
.admin-manager {
    background-color: white;
    border-right: 1px solid #e9ecef;
    overflow-y: auto;
    padding: 2rem;
}

/* Preview Column */
.admin-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f8f9fa;
    overflow-y: auto;
    height: 100vh;
    box-sizing: border-box;
}

.iphone-mockup {
    background: #1a1a1a;
    border-radius: 50px;
    padding: 8px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    width: 275px;
    height: 602px;
    position: relative;
    margin-bottom: 1rem;
}

.iphone-mockup::before {
    content: '';
    position: absolute;
    width: 150px;
    height: 25px;
    background: #1a1a1a;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    z-index: 2;
}

.iphone-screen {
    background: white;
    border-radius: 40px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.preview-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.preview-button {
    font-family: inherit;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    color: black;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: 1px solid #e9ecef;
    background: none;
    cursor: pointer;
    font-size: 1rem;
}

.preview-button:hover {
    background-color: white;
}

.preview-button svg {
    width: 16px;
    height: 16px;
} 