.shop-manager {
    margin-top: 30px;
    padding-top: 20px;
}

.bio-manager {
    padding: 20px;
}

.shop-manager textarea {
    width: 100%;
    min-height: 100px;
    padding: 12px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    resize: vertical;
    margin-bottom: 24px;
    background-color: #fafafa;
    transition: border-color 0.2s, background-color 0.2s;
}

.shop-manager h4 {
    margin-bottom: 12px;
    color: #222;
}

.listings-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
}

.listing-item {
    display: flex;
    align-items: center;
    padding: 12px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    background: white;
}

.listing-item img {
    width: 75px;
    height: 75px;
    object-fit: cover;
}

.listing-details {
    flex: 1;
    margin: 0 12px;
}

.listing-title {
    margin: 0;
    font-size: 14px;
}

.listing-price {
    margin: 4px 0 0;
    color: #666;
    font-size: 12px;
}

.listing-item button {
    padding: 6px 12px;
    border-radius: 6px;
    border: 1px solid #F1641D;
    background: white;
    color: #F1641D;
    cursor: pointer;
    font-family: inherit;
}

.listing-item button:hover {
    background: #F1641D;
    color: white;
}

.listing-item button.selected {
    background: #F1641D;
    color: white;
}

.listing-item button.selected:hover {
    background: white;
    color: #F1641D;
}

.about-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.about-section button {
    align-self: flex-end;
    padding: 8px 16px;
    background-color: #F1641D;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: inherit;
}

.about-section button:disabled {
    background-color: #f8f9fa;
    color: #666;
    border: 1px solid #e9ecef;
    cursor: not-allowed;
}

.about-section textarea:focus {
    outline: none;
    border-color: #ccc;
    background-color: white;
}