.linksy-design {
    margin-top: 30px;
    padding-top: 20px;
}

.design-section {
    margin-bottom: 48px;
}

.design-section h4 {
    margin-bottom: 12px;
    color: #333;
}

.color-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

.linksy-design label {
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 14px;
    color: #333;
}

.linksy-design input[type="color"] {
    width: 100%;
    height: 40px;
    padding: 2px;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    cursor: pointer;
}

.linksy-design select {
    width: 100%;
    padding: 8px;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    margin-bottom: 12px;
}

.linksy-design select option {
    font-size: 16px;
    padding: 8px;
}

.linksy-design select optgroup {
    font-weight: bold;
    color: #333;
}

.size-inputs {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.size-control {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #000000;
}

.size-control input[type="range"] {
    flex: 1;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    height: 16px;
    background: transparent;
}

.size-control input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #e9ecef;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -4px;
}

.size-control input[type="range"]::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #e9ecef;
    border-radius: 50%;
    cursor: pointer;
    border: none;
}

.size-control input[type="range"]::-ms-thumb {
    width: 16px;
    height: 16px;
    background: #e9ecef;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0;
}

.size-control input[type="range"]::-webkit-slider-runnable-track {
    background: #e9ecef;
    border-radius: 10px;
    height: 8px;
    width: 100%;
}

.size-control input[type="range"]::-moz-range-track {
    background: #e9ecef;
    border-radius: 10px;
    height: 8px;
    width: 100%;
}

.size-control input[type="range"]::-ms-track {
    background: #e9ecef;
    border-radius: 10px;
    height: 8px;
    width: 100%;
    border-color: transparent;
    color: transparent;
}

.size-control span {
    min-width: 45px;
    font-size: 14px;
    color: #333;
}

.font-select option {
    font-size: 16px;
    padding: 12px 8px;
    min-height: 24px;
    display: flex;
    align-items: center;
}

.font-select optgroup {
    font-weight: bold;
    color: #333;
} 