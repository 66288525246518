.profile-container {
    position: relative;
    width: 100%;
}

.profile-button {
    width: 100%;
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inherit;
}

.profile-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75rem;
    text-align: center;
    width: 100%;
}

.profile-menu {
    position: fixed;
    bottom: 50px;
    left: 100px;
    width: 200px;
    background: white;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    z-index: 1000;
}

.profile-menu button {
    width: 100%;
    padding: 0.75rem 1rem;
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
    font-size: 0.875rem;
    color: #333;
    font-family: inherit;
}

.profile-menu button:hover {
    background-color: #f8f9fa;
}

.profile-menu .delete-account {
    color: #dc3545;
}

.profile-menu .delete-account:hover {
    background-color: #ffebee;
} 