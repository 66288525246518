.signup-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem;
    background-color: #f8f8f8;
}

.signup-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
}

.signup-form h2 {
    margin-bottom: 2rem;
    color: #222;
    text-align: center;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333333;
    font-weight: 500;
}

.form-group input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
}

.form-group.readonly input {
    background-color: #f5f5f5;
    color: #666;
    cursor: not-allowed;
}

.signup-submit {
    width: 100%;
    background-color: #F1641D;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
}

.signup-submit:hover {
    background-color: #dc5816;
}

.error {
    color: #d93025;
    text-align: center;
    padding: 1rem;
    background: #fde7e7;
    border-radius: 8px;
    margin-bottom: 1rem;
}
