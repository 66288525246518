.auth-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.auth-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.auth-form h2 {
    text-align: left;
    margin-bottom: 2rem;
}

.auth-button {
    width: 100%;
    background-color: #F1641D;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 1rem;
}

.auth-button:hover {
    opacity: 0.9;
}

.forgot-password {
    text-align: center;
    margin-bottom: 2rem;
}

.forgot-password a {
    color: #666;
    text-decoration: none;
    font-size: 0.9rem;
}

.forgot-password a:hover {
    text-decoration: underline;
}

.divider {
    text-align: center;
    margin: 1.5rem 0;
    position: relative;
}

.divider::before,
.divider::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 45%;
    height: 1px;
    background-color: #ddd;
}

.divider::before {
    left: 0;
}

.divider::after {
    right: 0;
}

.callback-loading,
.callback-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    text-align: center;
    padding: 2rem;
}

.callback-error {
    color: #d93025;
}

.callback-loading p {
    color: #666;
    margin-top: 1rem;
}

.signup-button {
    width: 100%;
    background-color: #F1641D;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 1rem;
}
