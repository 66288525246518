.messages-manager {
    margin-top: 30px;
    padding-top: 20px;
}

.messages-manager h4 {
    margin-bottom: 20px;
    color: #333;
}

.messages-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.message-item {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.message-item.unread {
    background: #f8f9fa;
    border-left: 3px solid #f1641d;
}

.message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.message-from {
    font-weight: bold;
    color: #333;
}

.message-date {
    color: #666;
    font-size: 0.9em;
}

.message-email {
    color: #666;
    font-size: 0.9em;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.copy-button {
    background: none;
    border: none;
    padding: 2px;
    cursor: pointer;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    transition: all 0.2s ease;
    position: relative;
}

.copy-button:hover {
    opacity: 1;
}

.copy-button.copied {
    color: #666;
    opacity: 1;
}

.copied-text {
    font-size: 0.8em;
    color: #666;
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.message-content {
    color: #333;
    line-height: 1.4;
    white-space: pre-wrap;
}

.message-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
} 