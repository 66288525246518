.social-manager {
    margin-top: 30px;
    padding-top: 20px;
}

.social-manager h4 {
    margin-bottom: 12px;
    color: #333;
}

.social-links-list {
    margin: 20px 0;
}

.social-link-item {
    display: flex;
    align-items: center;
    padding: 12px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    margin-bottom: 8px;
    background: white;
}

.social-icon {
    margin-right: 12px;
    font-size: 20px;
}

.social-platform {
    font-weight: 500;
    min-width: 80px;
}

.link-url {
    flex: 1;
    margin: 0 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #666;
}

.action-buttons {
    display: flex;
    gap: 8px;
}

.edit-button,
.delete-button {
    background: none;
    border: none;
    padding: 6px;
    cursor: pointer;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-button:hover,
.delete-button:hover {
    color: #F1641D;
}

/* Style for the editing state */
.social-link-item.editing {
    background-color: #fafafa;
}

.edit-input {
    flex: 1;
    margin: 0 12px;
    padding: 6px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
}

.edit-actions {
    display: flex;
    gap: 8px;
}

.edit-actions button {
    padding: 6px 12px;
    border-radius: 6px;
    border: 1px solid #F1641D;
    background: white;
    color: #F1641D;
    cursor: pointer;
}

.edit-actions button:hover {
    background: #F1641D;
    color: white;
}

.add-channel-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #F1641D;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.add-channel-button:disabled {
    background-color: #f8f9fa;
    cursor: not-allowed;
}

.add-channel-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.add-channel-form select,
.add-channel-form input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-buttons {
    display: flex;
    gap: 8px;
}

.form-buttons button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-buttons button[type="submit"] {
    background-color: #F1641D;
    color: white;
}

.form-buttons button[type="button"] {
    background-color: #ddd;
}

.input-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.input-help {
    color: #666;
    font-size: 0.8rem;
    margin-top: -2px;
}

.link-url {
    color: #666;
    font-size: 0.9rem;
    word-break: break-all;
}
