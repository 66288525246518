.public-linksy-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.public-linksy-container.error {
    justify-content: center;
    align-items: center;
    color: #ff4444;
    padding: 20px;
    text-align: center;
} 