.linksy-container {
    min-height: 100vh;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: relative;
}

.linksy-header {
    text-align: center;
}

.linksy-header h1 {
    margin-bottom: 20px;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin: 16px 0;
}

.linksy-links {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 20px;
}

.linksy-link {
    width: 100%;
    padding: 16px 20px;
    text-align: center;
    text-decoration: none;
    display: block;
}

/* Add these new styles for button elements */
button.linksy-link {
    width: 100%;
    border: none;
    font-family: inherit;
    cursor: pointer;
    background: none;  /* Remove default button background */
    margin: 0;         /* Remove default button margin */
    font-size: inherit;/* Inherit font size */
    line-height: normal; /* Reset line height */
}

.linksy-link:hover {
    opacity: var(--hover-opacity, 1);
    transform: var(--hover-transform, none) !important;
}

.avatar {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    border: 1px solid #0B1215;
    object-fit: cover;
    margin: 0 auto 20px;
} 


/* Position footer at bottom center of the page */
.linksy-footer {
    position: sticky;
    bottom: 10px;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    margin-top: auto;
}

.linksy-footer:hover {
    text-decoration: none;
}

.linksy-footer button {
    text-align: center;
    font-family: inherit;
    font-style: italic;
    font-size: .75rem;
    padding: 8px 16px;
    border-radius: 16px;
    text-decoration: none;
    background: #ffffff;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #0b1215;
    transition: transform 0.2s ease;
}

.linksy-footer button:hover {
    transform: translateY(-2px);
}

.tabs-container {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    padding: 0 20px;
}

.tab {
    background: none;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 1rem;
    font-family: inherit;
    position: relative;
    transition: opacity 0.2s;
}

.tab:hover {
    opacity: 0.8;
}

.tab.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: currentColor;
}

.linksy-shop {
    width: 100%;
    padding: 0 20px;
}

.linksy-listings {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
    padding: 8px;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.linksy-listing {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    padding: 12px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
}

.linksy-listing:hover {
    opacity: var(--hover-opacity, 1);
    transform: var(--hover-transform, none) !important;
}

.linksy-listing img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 8px;
}

.linksy-listing .listing-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.linksy-listing h3 {
    margin: 0;
    font-size: 14px;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.listing-meta {
    display: flex;
    gap: 12px;
    justify-content: center;
    font-size: 12px;
    opacity: 0.9;
}

.favorites {
    display: flex;
    align-items: center;
    color: #0b1215;
    gap: 4px;
}

.price {
    color: #0b1215;
}

/* Mobile-specific adjustments */
@media (max-width: 480px) {
    .linksy-listings {
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
        padding: 8px;
    }

    .linksy-listing {
        padding: 8px;
    }

    .linksy-listing h3 {
        font-size: 13px;
    }

    .listing-meta {
        font-size: 11px;
        gap: 8px;
    }
}

.social-icon-link {
    font-size: 24px;
    color: inherit;
    transition: opacity 0.2s;
}

.social-icon-link:hover {
    opacity: 0.8;
}

.contact-button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact-button-container .contact-form-overlay {
    margin-top: -8px; /* Adjust to create a nice connection with the button */
}