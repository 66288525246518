.contact-form-overlay {
    position: static;
    background: none;
    display: block;
}

.contact-form-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    box-sizing: border-box;
    position: relative;
    
    /* Animation properties */
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    transition: all 0.3s ease-out;
    margin: 0;
}

.contact-form-modal.open {
    max-height: 1000px;
    opacity: 1;
    visibility: visible;
    margin: 8px 0;
}

.close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
}

.contact-form-modal h3 {
    margin: 0 0 20px 0;
    text-align: center;
}

.contact-form-modal form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.contact-form-modal input,
.contact-form-modal textarea {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: inherit;
}

.contact-form-modal textarea {
    min-height: 120px;
    resize: vertical;
}

.contact-form-modal button[type="submit"] {
    padding: 12px;
    background: #f1641d;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: inherit;
}

.contact-form-modal button[type="submit"]:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.form-content,
.success-message {
    transition: opacity 0.3s ease;
    width: 100%;
    box-sizing: border-box;
}

.form-content {
    opacity: 1;
    visibility: visible;
    position: relative;
}

.form-content.hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.success-message {
    opacity: 0;
    visibility: hidden;
    display: none;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    color: #28a745;
    padding: 20px;
}

.success-message.visible {
    opacity: 1;
    visibility: visible;
    display: flex;
}

.error-message {
    color: #dc3545;
    text-align: center;
    font-size: 14px;
}

/* Mobile optimizations */
@media (max-width: 480px) {
    .contact-form-modal {
        padding: 12px;
    }

    .contact-form-modal input,
    .contact-form-modal textarea {
        padding: 8px;
        font-size: 13px;
    }

    .contact-form-modal h3 {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .contact-form-modal button[type="submit"] {
        padding: 8px;
        font-size: 13px;
    }

    .success-message {
        font-size: 13px;
        padding: 12px;
    }

    .error-message {
        font-size: 11px;
    }
}

/* Add styles for the contact button indicator */
.contact-button-container .linksy-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.contact-indicator {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-size: 12px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    transition: transform 0.2s ease;
}

.contact-button-container .linksy-link:hover .contact-indicator {
    transform: translateY(-2px);
} 